import React ,{ Component }from "react";
import { FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Entreprise à la recherche d’un profil freelance',
        description: 'Besoin d’un profil expérimenté pour réaliser un projet ? Nous vous trouvons la personne qu’il vous faut !'
    },
    {
        icon: <FiLayers />,
        title: 'Entreprise à la recherche d’un profil en CDI',
        description: 'Besoin d’un profil expérimenté sur le long terme ? Nous pouvons vous dénicher la perle rare.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Candidat qui recherche l’accompagnement d’une SSII',
        description: 'Si vous êtes à la recherche d’un accompagnement sur-mesure, vous frappez à la bonne porte.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/service-details">
                                <div className="service service__style--2 text-left bg-gray">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
