import React, { Component } from "react";
import FormStyle from './FormStyle'

class ContactTwo extends Component{
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <span className="subtitle">Contactez-nous</span>
                                <h2 className="title">Parlez-nous de vous</h2>
                                <div className="im_address">
                                    <span>Tél:</span>
                                    <a className="link im-hover" target="_blank" href="https://api.whatsapp.com/send?text=212668135905">+212 6 68 13 59 05</a>
                                </div>
                                <div className="im_address mt--5">
                                    <span>Email:</span>
                                    <a className="link im-hover" href={`mailto:${'contact@neo-devit.com'}?subject=${encodeURIComponent('Proposition de collaboration') || ''}&body=${encodeURIComponent('Bonjour') || ''}`}>contact@neo-devit.com</a>
                                </div>
                            </div>
                            <FormStyle />
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <div style={{ paddingTop: "120px" }}>
                                 <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.8477441442155!2d-7.624483340616956!3d33.58330223541723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d2bd4b17ef47%3A0xe2fde06a9ae710b8!2sr%C3%A9sidence%20les%20fleurs%2C%2059%20Bd%20Mohamed%20Zerktouni%2C%20Casablanca%2022000%2C%20Maroc!5e0!3m2!1sfr!2suk!4v1676972934651!5m2!1sfr!2suk"
                                        width="516"
                                        height="516"
                                        style={{ border: "0" }}
                                        allowFullScreen=""
                                        loading="lazy"
                                ></iframe>
                                </div>
           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;