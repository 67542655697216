import React from 'react'

import Home from './Home'


const RenderHome = () => {

    return (
        (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ?  
        <div className="active-dark">
            <Home />
        </div> : <Home /> 
      
    )
}

export default RenderHome;